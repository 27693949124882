<script setup>
import { onMounted, ref } from "vue";
import { useColorModes } from "@coreui/vue";

import { CContainer } from "@coreui/vue/dist/esm/components/grid/index.js";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/vue/dist/esm/components/dropdown/index.js";
import {
  CHeader,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/vue/dist/esm/components/header/index.js";
// eslint-disable-next-line import/no-named-as-default
import CIcon from "@coreui/icons-vue";
import { useSidebarStore } from "~/stores/sidebar.js";
import AppHeaderDropdownAccnt from "@/components/AppHeaderDropdownAccnt.vue";

const headerClassNames = ref("mb-4 p-0");
const { colorMode, setColorMode } = useColorModes(
  "coreui-free-vue-admin-template-theme",
);
const sidebar = useSidebarStore();

onMounted(() => {
  document.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 0) {
      headerClassNames.value = "mb-4 p-0 shadow-sm";
    } else {
      headerClassNames.value = "mb-4 p-0";
    }
  });
});
</script>

<template>
  <CHeader position="sticky" :class="headerClassNames">
    <CContainer class="border-bottom px-4" fluid>
      <CHeaderToggler
        style="margin-inline-start: -14px"
        @click="sidebar.toggleVisible()"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- CHeaderNav class="d-none d-md-flex">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav -->
      <!-- CHeaderNav class="ms-auto">
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
      </CHeaderNav -->
      <CHeaderNav>
        <!-- li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li -->
        <CDropdown variant="nav-item" placement="bottom-end">
          <CDropdownToggle :caret="false">
            <CIcon v-if="colorMode === 'dark'" icon="cil-moon" size="lg" />
            <CIcon v-else-if="colorMode === 'light'" icon="cil-sun" size="lg" />
            <CIcon v-else icon="cil-contrast" size="lg" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem
              :active="colorMode === 'light'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('light')"
            >
              <CIcon class="me-2" icon="cil-sun" size="lg" />
              {{ $t("appheader.button.light") }}
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'dark'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('dark')"
            >
              <CIcon class="me-2" icon="cil-moon" size="lg" />
              {{ $t("appheader.button.dark") }}
            </CDropdownItem>
            <!-- CDropdownItem
              :active="colorMode === 'auto'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('auto')"
            >
              <CIcon class="me-2" icon="cil-contrast" size="lg" /> {{ $t('appheader.button.auto') }}
            </CDropdownItem -->
          </CDropdownMenu>
        </CDropdown>
        <li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>
