<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} {{ config.public.copyright }} All
        rights reserved.</span
      >
    </div>
    <div class="footer-locale-selector">
      <LocaleSelector class="mx-3" />
    </div>
    <div class="ms-auto">
      <span class="me-1">Powered by</span>
      <a href="https://softminds.net">Softminds</a>
    </div>
  </CFooter>
</template>
<script setup lang="ts">
import { CFooter } from "@coreui/vue/dist/esm/components/footer";
import LocaleSelector from "~/components/controls/LocaleSelector.vue";
const config = useRuntimeConfig();
</script>
